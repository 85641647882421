<template>
  <div>
    <video controls="true" width="100%">
      <source :src="mp4" type="video/mp4">
    </video>
  </div>
</template>
<script>
export default {
  name: 'Video',
  props: {
    mp4: String
  }
}
</script>
